import { post, get } from './http'
export const getSmsCode = getSmsCode => get('/user/getSmsCode', getSmsCode); //获取验证码
export const login = login => post('/user/login', login); //登录
export const regist = regist => post('/user/regist', regist); //注册
export const realAuth = realAuth => post('/realAuth/realAuth', realAuth); //实名认证
export const updatePassWord = updatePassWord => post('/user/updatePassWord', updatePassWord); //修改密码
export const getBankList = getBankList => get('/bank/getBankList', getBankList); //获取银行卡的列表
export const getRealAuth = getRealAuth => get('/realAuth/getRealAuth', getRealAuth); //获取实名信息


export const getPayChannelList = getPayChannelList => get('/payChannel/getPayChannelList', getPayChannelList); //支付通道接口

export const bindingBankCard = bindingBankCard => post('/agreementWithholdingOrder/bindingBankCard', bindingBankCard); //立即绑定
export const confirmBindBankCard = confirmBindBankCard => post('/agreementWithholdingOrder/confirmBindingBankCard', confirmBindBankCard); //通过验证码确认绑卡


export const getFaceIdentityUrl = getFaceIdentityUrl => get('/realAuth/getFaceIdentityUrl', getFaceIdentityUrl); //获取用户刷脸核验地址

export const FaceIdentityResult = FaceIdentityResult => get('/realAuth/getFaceIdentityResult', FaceIdentityResult); //获取用户刷脸核验结果
export const createContract = createContract => post('/contract/createContract', createContract); //创建合同
export const confirmtractSign = confirmtractSign => post('/contract/confirmtractSign', confirmtractSign); //验证签署短信


export const getOrderList = getOrderList => get('/agreementWithholdingOrder/getOrderList', getOrderList); //根据订单状态获取订单列表
export const getOrderPlanListt = getOrderPlanListt => get('/agreementWithholdingOrder/getOrderPlanList', getOrderPlanListt); //根据订单ID获取扣款计划列表

export const cancelOrder = cancelOrder => post('/agreementWithholdingOrder/cancelOrder', cancelOrder); //取消订单

export const cancelOrderPlan = cancelOrderPlan => post('/agreementWithholdingOrder/cancelOrderPlan', cancelOrderPlan); //取消订单扣款计划
export const executePlan = executePlan => post('/agreementWithholdingOrder/executePlan', executePlan); //发起扣款

export const getContractImage = getContractImage => get('/contract/getContractImage', getContractImage); //预览合同图片
export const getAccountInfo = getAccountInfo => get('/user/getAccountInfo', getAccountInfo); //获取当前登录用户账号信息

export const getSelfShareUrl = getSelfShareUrl => get('/user/getSelfShareUrl', getSelfShareUrl);

export const getCashoutList = getCashoutList => get('/user/getCashoutList', getCashoutList);

export const cashout = cashout => post('/user/cashout', cashout);

export const getNewsList = getNewsList => get('/news/getNewsList', getNewsList);


export const getOrderStatus = getOrderStatus => get('/agreementWithholdingOrder/getOrderStatus', getOrderStatus);



export const getDistributionInfo = getDistributionInfo => get('/distribution/getDistributionInfo', getDistributionInfo);

export const getSubDistributionList = getSubDistributionList => get('/distribution/getSubDistributionList', getSubDistributionList);

export const getSubIncomeList = getSubIncomeList => get('/distribution/getSubIncomeList', getSubIncomeList);