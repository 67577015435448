import axios from 'axios'; // 引入axios
// import store from '@/store/index';
import router from '../router'
import { Notify } from 'vant';
// import QS from 'qs'

if (process.env.NODE_ENV == 'development') {

    // axios.defaults.baseURL = 'http://192.168.0.70:8085/';
    axios.defaults.baseURL = 'http://fengshou.zjshuke.com:8085/';
    // axios.defaults.baseURL = 'http://fengshou.qjsw.online:8085/';

} else if (process.env.NODE_ENV == 'production') {

    axios.defaults.baseURL = 'http://fengshou.zjshuke.com:8085/';

    // axios.defaults.baseURL = 'http://fengshou.qjsw.online:8085/';

}
const urlData = 'http://fengshou.zjshuke.com/' + '#/shimingwait'

// const urlData = 'http://192.168.0.12:8080/' + '#/shimingwait'

localStorage.setItem('domain', JSON.stringify(urlData))

const codewaitData = 'http://fengshou.zjshuke.com/' + '#/codewait'
// const codewaitData = 'http://192.168.0.12:8080/' + '#/codewait'


localStorage.setItem('codewait', JSON.stringify(codewaitData))

// axios.defaults.timeout = 10000;
//请求拦截器
axios.interceptors.request.use(
    config => {
        config.headers['content-type'] = 'application/json;charset=UTF-8';
        const Jtokens = localStorage.getItem('withhold_tokens');
        const tokens = JSON.parse(Jtokens)
        if (tokens) {
            tokens && (config.headers.Authorization = tokens);
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

// 响应拦截器
axios.interceptors.response.use((response) => {
    // console.log(response)
    const res = response.data
    console.log(res)
    // 处理请求200的操作，默认不需要操作，可直接返回 return 返回正确信息调用接口时可以直接promise then 取到正确信息
    if (res.code == '401') {
        Notify('登录过期，将重新登录')
        localStorage.removeItem('withhold_tokens')
        localStorage.removeItem('withhold_userData')
        router.push('/login')
    } else if (res.code == '400') {
        Notify(res.message)
    } else if (res.code == '500') {
        Notify(res.message)

    } else {
        return response;
    }
    // else if (res.errno != 0) {
    //     // 非5xx的错误属于业务错误，留给具体页面处理
    //     return response;
    // }
}, (error) => {
    this.$notify({ type: "danger", duration: 5000, message: "登录连接超时（后台不能连接，请联系系统管理员" });
    // return error 返回错误
    // this.$router.push("/login");
    return Promise.reject(error)
})

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}